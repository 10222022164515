export class HttpError extends Error {
  status: number
  statusText: string
  data: any

  constructor (status: number, statusText: string, data?: any) {
    super(statusText)
    this.status = status
    this.statusText = statusText
    this.data = data
  }
}
