import { loadStripe, Stripe } from '@stripe/stripe-js'

let key: string | null = null
let stripe: Stripe | null = null

export function initializeStripe (publicKey: string): void {
  key = publicKey
}

export async function getStripe (): Promise<Stripe> {
  if (key === null) {
    throw new Error('Must call initializeStripe() first')
  }
  stripe = stripe ?? await loadStripe(key)
  if (stripe === null) {
    throw new Error('Unable to load stripe-js')
  }
  return stripe
}
