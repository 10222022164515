import cx from 'classnames'

export default function Logo (): JSX.Element {
  return (
    <div
      className={cx(
        'font-extrabold tracking-tight leading-none text-green-800 text-lg'
      )}
    >
      ROGER
    </div>
  )
}
